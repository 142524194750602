import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectableTile, Step } from './../../components';
import styles from './ColorList.module.scss';
import { Case } from '../../helper/interface/cases.interface';

interface ColorItem {
  id: number;
  name: string;
  gradation?: string;
  HEX?: string;
  RGB?: string;
  CMYK?: string;
  PMS?: string;
  RAL?: string;
}

export interface ColorListProps {
  onColorSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  case: string;
}

const checkIfCaseHasSubcategory = (caseData: Case) => {
  const caseKeys = Object.keys(caseData);
  return caseKeys.includes('subcategories');
};

const returnTranslatedColorValues = (
  colorData: ColorItem[],
  colorIds: (string | number)[]
) => {
  const tempColors: { [key: number]: ColorItem } = {};
  colorData.forEach((item: ColorItem) => {
    tempColors[item.id] = item;
  });
  const selectedColors = colorIds?.map((item: any) => tempColors[item]);
  return selectedColors;
};

const returnTranslatedColorValuesWithSubcategory = (
  subcategories: {
    [name: string]: number[];
  },
  colorData: ColorItem[]
) => {
  const colorsWithSub: { [key: string]: ColorItem[] } = {};
  // for each subcategory, create an entry in an object with the subcategory name as key and the colors as value and return the object
  Object.entries(subcategories).forEach(([key, value]) => {
    colorsWithSub[key] = returnTranslatedColorValues(colorData, value);
  });

  return colorsWithSub;
};

export function ColorList(props: ColorListProps) {
  const [isSelected, setIsSelected] = useState<string>('');
  const { t } = useTranslation(['common', 'cases', 'colors']);
  const translatedCaseData: Case = t(`cases:cases.${props.case}`, {
    returnObjects: true,
  });
  const translatedColorData: [] = t('colors:colors', { returnObjects: true });
  const caseWithSubcategory = checkIfCaseHasSubcategory(translatedCaseData);

  useEffect(() => {
    setIsSelected('');
  }, [props.case]);

  const colors = returnTranslatedColorValues(
    translatedColorData,
    translatedCaseData.colors
  );

  const colorsWithSub = caseWithSubcategory && {
    ...returnTranslatedColorValuesWithSubcategory(
      translatedCaseData.subcategories,
      translatedColorData
    ),
  };

  const radioChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSelected(event.target.value);
    if (props.onColorSelect) {
      props.onColorSelect(event);
    }

    // jump to next step
    const stepID = t('colors.stepId') + 1;
    setTimeout(() => {
      const stepElement = document.getElementById(`step` + stepID);
      if (stepElement) {
        stepElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  };

  return (
    <div className={styles.TileList}>
      <Step step={t('colors.stepId')} title={t('colors.stepTitle')}></Step>
      <div className={caseWithSubcategory ? styles.ItemsSub : styles.Items}>
        {caseWithSubcategory &&
          colorsWithSub &&
          Object.entries(colorsWithSub).map(
            ([key, value]: [string, ColorItem[]]) => {
              return (
                <div key={key} className={styles.Subcategory}>
                  <h3 className={styles.ItemSubHeadline}>{key}</h3>
                  <div className={styles.Items}>
                    {value.map(
                      (
                        { name, gradation, HEX }: any,
                        index: React.Key | null | undefined
                      ) => {
                        return (
                          <div key={index} className={styles.Item}>
                            <SelectableTile
                              background={HEX}
                              change={radioChangeHandler}
                              gradation={gradation}
                              isSelected={
                                isSelected ===
                                name +
                                  (gradation !== undefined
                                    ? ' ' + gradation
                                    : '')
                              }
                              label={name}
                              name={'color'}
                              value={
                                name +
                                (gradation !== undefined ? ' ' + gradation : '')
                              }
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            }
          )}

        {colors &&
          !caseWithSubcategory &&
          colors.map(
            (
              { name, gradation, HEX }: any,
              index: React.Key | null | undefined
            ) => {
              return (
                <div key={index} className={styles.Item}>
                  <SelectableTile
                    background={HEX}
                    change={radioChangeHandler}
                    gradation={gradation}
                    isSelected={
                      isSelected ===
                      name + (gradation !== undefined ? ' ' + gradation : '')
                    }
                    label={name}
                    name={'color'}
                    value={
                      name + (gradation !== undefined ? ' ' + gradation : '')
                    }
                  />
                </div>
              );
            }
          )}
      </div>
    </div>
  );
}
