import { useTranslation } from 'react-i18next';
import { getColorById } from '../../helper/functions/getColorById';
import { Case } from '../../helper/interface/cases.interface';
import styles from './ColorGradations.module.scss';

export interface ColorGradationsProps {
  case: string;
  color: string | [];
  gradation?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const returnAllGradationsByColorName = (
  colorData: any[],
  colorName: string
) => {
  const selectedColors = colorData.filter((item) => item.name === colorName);
  return selectedColors;
};

export function ColorGradations(props: ColorGradationsProps) {
  const { t } = useTranslation(['common', 'cases', 'colors', 'hints']);
  // const hintData: [] = t('hints:hints', { returnObjects: true });
  const caseData: Case = t(`cases:cases.${props.case}`, {
    returnObjects: true,
  });
  const colorData: [] = t('colors:colors', { returnObjects: true });
  const selectedCase = caseData;
  // create color filter by selected case and codes prop
  const colorFilter = selectedCase.codes;
  // get copy link button icon
  // const codesIcon = selectedCase.codesIcon;

  let gradations: any[] = [];
  // Color hints
  // let getColorHint = (key: string) => {
  //   return getValueByKey(getHintByColor(key, hintData), 'hint');
  // };

  // handle click event
  // const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
  //   if (props.onClick) {
  //     props.onClick(event);
  //   }
  // };

  if (Array.isArray(props.color) && props.color.length > 0) {
    props.color.forEach((item: number) => {
      gradations.push(getColorById(colorData, item));
    });
  } else {
    gradations = returnAllGradationsByColorName(
      colorData,
      props.color as string
    );
  }

  const showCopyButtonsByColor = (
    color: any,
    filter: string,
    index: number
  ) => {
    for (const [key, value] of Object.entries(color)) {
      if (filter.includes(key)) {
        return (
          <li className={styles.ListItem} key={index}>
            <p>{`${key}: ${value}`}</p>
            {/* <Button
              action={`show`}
              ariaLabel={`${t('colors.copyAria')}`}
              class={styles.ColorCopyButton}
              copy={true}
              icon={true}
              iconName={codesIcon}
              iconType={`boschicon-bosch`}
              key={index}
              labelText={`${key} ${value}`}
              onClick={handleClick}
              tooltip={true}
              tooltipText={getColorHint(key)}
              tooltipWidth={`fixed`}
              variant={`secondary`}
            /> */}
          </li>
        );
      }
    }
  };

  const gradationWorker = gradations.map((colorItem, index) => {
    return (
      <li className={styles.Color} key={index}>
        <span
          className={
            colorItem.name === t('colors:colors.134.name')
              ? `${styles.BoxBorder} ${styles.ColorTile}`
              : `${styles.ColorTile}`
          }
          style={{ backgroundColor: colorItem.HEX }}
        ></span>
        <span className={styles.ColorName}>
          {colorItem.name} {colorItem.gradation}
        </span>
        <ul className={styles.ButtonList}>
          {colorFilter &&
            colorFilter.map((item: string, index: number) =>
              showCopyButtonsByColor(colorItem, item, index)
            )}
        </ul>
      </li>
    );
  });

  return (
    <ul className={styles.ColorList}>
      {gradationWorker && gradationWorker.length
        ? gradationWorker
        : t('gradations.error')}
    </ul>
  );
}
