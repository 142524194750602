import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './Notification.scss';

export interface NotificationProps {
  banner?: boolean;
  bannerVisibility?: boolean;
  icon?: string;
  iconType?: string;
  id: string;
  message: string;
  type: string;
}

export function Notification(props: NotificationProps) {
  const { t } = useTranslation(['common']);
  const barIcon = () => {
    return (
      <i
        className={`a-icon ${props.iconType}-ic-${props.icon}`}
        title={props.icon}
      ></i>
    );
  };

  const notificationRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    notificationRef.current?.classList.remove('-show');
  };

  return (
    <div
      className={`a-notification ${
        props.banner ? 'a-notification--banner' : ''
      } -${props.type} ${props.bannerVisibility ? '-show' : ''}`}
      role='alert'
      ref={notificationRef}
    >
      {props.icon && props.icon.length !== 0 && barIcon()}
      <div
        id={`${props.id}`}
        className={`a-notification__content`}
        dangerouslySetInnerHTML={{ __html: props.message }}
      ></div>
      {props.banner && (
        <button
          type={`button`}
          className={`a-button a-button--integrated -without-label`}
          data-frok-action={`close`}
          aria-label={`${t('common:app.closeNotification')}`}
          onClick={handleClose}
        >
          <i
            className={`a-icon a-button__icon ui-ic-close`}
            title={`${t('common:app.closeIconTitle')}`}
          ></i>
        </button>
      )}
    </div>
  );
}
