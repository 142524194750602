import { useTranslation } from 'react-i18next';
import { Accordion } from '../Accordion';
import styles from './AdditionalOptions.module.scss';
import { Case } from '../../helper/interface/cases.interface';

export interface AdditionalOptionsProps {
  case: string;
  color: string;
  gradation: string;
}

export function AdditionalOptions(props: AdditionalOptionsProps) {
  const { t } = useTranslation(['common', 'cases']);
  const caseData: Case = t(`cases:cases.${props.case}`, {
    returnObjects: true,
  });
  const accordionList: JSX.Element[] = [];
  const additionalDataByCase = caseData.additional;

  // build accordions
  additionalDataByCase.forEach((item, index) => {
    let listColors: string | [] = props.color;

    if (Array.isArray(item.colors) && item.colors.length > 0) {
      listColors = item.colors as [];
    }

    accordionList.push(
      <Accordion
        case={props.case}
        color={listColors}
        gradation={props.gradation}
        id={index}
        key={index}
        sub={item.sub}
        title={item.title}
      />
    );
  });

  return (
    <div
      className={`${styles.AccordionList} ${
        props.case === 'Print' &&
        (props.color === 'White' || props.color === 'Weiß')
          ? styles.HideFirstAccordion
          : ''
      }`}
    >
      <h2>{t('additionalOptions.title')}</h2>
      {accordionList && accordionList.length
        ? accordionList
        : t('additionalOptions.error')}
    </div>
  );
}
