import React, { useState } from 'react';
import {
  AdditionalOptions,
  CaseList,
  ColorList,
  RelatedLinks,
  Results,
} from './components';
import { naturalMatch } from './helper/functions/naturalMatch';
import './scss/App.scss';
import { LanguageChooser } from './components/LanguageChooser';

function App() {
  const [selectedCase, setSelectedCase] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<string>('');

  const resetRadiostate = () => {
    setSelectedColor('');
  };

  let colorName = '';
  let colorGradation = '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'case') {
      resetRadiostate();
      setSelectedCase(e.target.value);
    }

    if (e.target.name === 'color') {
      setSelectedColor(e.target.value);
    }
  };

  if (selectedColor) {
    colorName = naturalMatch(selectedColor)[0].toString();

    if (naturalMatch(selectedColor).length > 1) {
      colorGradation = naturalMatch(selectedColor)[1].toString();
    }
  }

  return (
    <div className="App container">
      <LanguageChooser />

      <CaseList onCaseSelect={handleChange} />
      {selectedCase && (
        <ColorList onColorSelect={handleChange} case={selectedCase} />
      )}
      {selectedColor && selectedCase && (
        <Results
          case={selectedCase}
          color={colorName}
          gradation={colorGradation}
        />
      )}
      {selectedColor && selectedCase && (
        <AdditionalOptions
          case={selectedCase}
          color={colorName}
          gradation={colorGradation}
        />
      )}
      {selectedColor && selectedCase && <RelatedLinks case={selectedCase} />}
    </div>
  );
}

export default App;
