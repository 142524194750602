import { useTranslation } from 'react-i18next';
import {
  locationdesignBlue,
  locationdesignBrownYellow,
  locationdesignCoolGray,
  locationdesignGray,
  locationdesignGreen,
  locationdesignGreenYellow,
  locationdesignPurple,
  locationdesignTurquoise,
  locationdesignWarmGray,
  clothingBlack,
  clothingBlue,
  clothingConvoy,
  clothingGraphite,
  clothingGray,
  clothingGreen,
  clothingPurple,
  clothingTurquoise,
  clothingWhite,
  digitalBlue,
  digitalGreen,
  digitalPurple,
  digitalRed,
  digitalTurquoise,
  digitalYellow,
  giveawaysPurple,
  giveawaysBlue,
  giveawaysTurquoise,
  giveawaysGreen,
  giveawaysGray,
  giveawaysWhite,
  giveawaysBlack,
  powerpointBlue,
  powerpointGreen,
  powerpointPurple,
  powerpointTurquoise,
  printBlue,
  printGreen,
  printPurple,
  printTurquoise,
  printWhite,
} from '../../assets/img';
import styles from './Examples.module.scss';

export interface ExamplesProps {
  color: string;
  case: string;
  gradiation: string;
}

export function Examples(props: ExamplesProps) {
  let exampleImage;

  const { t } = useTranslation(['common']);
  const concatColor =
    props.color + (props.gradiation ? ' ' + props.gradiation : '');

  switch (props.case) {
    case 'Print':
    case 'Druck':
      switch (concatColor) {
        case 'Bosch Purple 40':
        case 'Bosch Purpur 40':
          exampleImage = printPurple;
          break;
        case 'Bosch Blue 50':
        case 'Bosch Blau 50':
          exampleImage = printBlue;
          break;
        case 'Bosch Turquoise 50':
        case 'Bosch Türkis 50':
          exampleImage = printTurquoise;
          break;
        case 'Bosch Green 50':
        case 'Bosch Grün 50':
          exampleImage = printGreen;
          break;
        case 'White':
        case 'Weiß':
          exampleImage = printWhite;
          break;
      }
      break;
    case 'PowerPoint':
      switch (concatColor) {
        case 'Bosch Purple 40':
        case 'Bosch Purpur 40':
          exampleImage = powerpointPurple;
          break;
        case 'Bosch Blue 50':
        case 'Bosch Blau 50':
          exampleImage = powerpointBlue;
          break;
        case 'Bosch Turquoise 50':
        case 'Bosch Türkis 50':
          exampleImage = powerpointTurquoise;
          break;
        case 'Bosch Green 50':
        case 'Bosch Grün 50':
          exampleImage = powerpointGreen;
          break;
      }
      break;
    case 'Clothing':
    case 'Bekleidung':
      switch (concatColor) {
        case 'Bosch Purple 40':
        case 'Bosch Purpur 40':
          exampleImage = clothingPurple;
          break;
        case 'Bosch Blue 50':
        case 'Bosch Blau 50':
          exampleImage = clothingBlue;
          break;
        case 'Bosch Turquoise 50':
        case 'Bosch Türkis 50':
          exampleImage = clothingTurquoise;
          break;
        case 'Bosch Green 50':
        case 'Bosch Grün 50':
          exampleImage = clothingGreen;
          break;
        case 'Bosch Gray 50':
        case 'Bosch Grau 50':
          exampleImage = clothingGray;
          break;
        case 'Black':
        case 'Schwarz':
          exampleImage = clothingBlack;
          break;
        case 'White':
        case 'Weiß':
          exampleImage = clothingWhite;
          break;
        case 'Convoy':
          exampleImage = clothingConvoy;
          break;
        case 'Graphite':
        case 'Graphit':
          exampleImage = clothingGraphite;
          break;
      }
      break;
    case 'Give Aways':
    case 'Werbegeschenke':
      switch (concatColor) {
        case 'Bosch Purple 40':
        case 'Bosch Purpur 40':
          exampleImage = giveawaysPurple;
          break;
        case 'Bosch Blue 50':
        case 'Bosch Blau 50':
          exampleImage = giveawaysBlue;
          break;
        case 'Bosch Turquoise 50':
        case 'Bosch Türkis 50':
          exampleImage = giveawaysTurquoise;
          break;
        case 'Bosch Green 50':
        case 'Bosch Grün 50':
          exampleImage = giveawaysGreen;
          break;
        case 'Bosch Gray 50':
        case 'Bosch Grau 50':
          exampleImage = giveawaysGray;
          break;
        case 'White':
        case 'Weiß':
          exampleImage = giveawaysWhite;
          break;
        case 'Black':
        case 'Schwarz':
          exampleImage = giveawaysBlack;
          break;
      }
      break;
    case 'Location Design':
    case 'Standortgestaltung':
      switch (concatColor) {
        case 'Bosch Purple 40':
        case 'Bosch Purpur 40':
          exampleImage = locationdesignPurple;
          break;
        case 'Bosch Blue 50':
        case 'Bosch Blau 50':
          exampleImage = locationdesignBlue;
          break;
        case 'Bosch Turquoise 50':
        case 'Bosch Türkis 50':
          exampleImage = locationdesignTurquoise;
          break;
        case 'Bosch Green 50':
        case 'Bosch Grün 50':
          exampleImage = locationdesignGreen;
          break;
        case 'Bosch Gray 50':
        case 'Bosch Grau 50':
          exampleImage = locationdesignGray;
          break;
        case 'Bosch Warm Gray 50':
        case 'Bosch Warmes Grau 50':
          exampleImage = locationdesignWarmGray;
          break;
        case 'Bosch Cool Gray 50':
        case 'Bosch Kühles Grau 50':
          exampleImage = locationdesignCoolGray;
          break;
        case 'Bosch Brown Yellow 50':
        case 'Bosch Braun Gelb 50':
          exampleImage = locationdesignBrownYellow;
          break;
        case 'Bosch Green Yellow 50':
        case 'Bosch Grün Gelb 50':
          exampleImage = locationdesignGreenYellow;
          break;
      }
      break;
    case 'Digital':
      switch (concatColor) {
        case 'Bosch Purple 40':
        case 'Bosch Purpur 40':
          exampleImage = digitalPurple;
          break;
        case 'Bosch Blue 50':
        case 'Bosch Blau 50':
          exampleImage = digitalBlue;
          break;
        case 'Bosch Turquoise 50':
        case 'Bosch Türkis 50':
          exampleImage = digitalTurquoise;
          break;
        case 'Bosch Green 50':
        case 'Bosch Grün 50':
          exampleImage = digitalGreen;
          break;
        case 'Bosch Gray 50':
        case 'Bosch Grau 50':
          exampleImage = digitalBlue;
          break;
        case 'Bosch Red 50':
        case 'Bosch Rot 50':
          exampleImage = digitalRed;
          break;
        case 'Bosch Yellow 85':
        case 'Bosch Gelb 85':
          exampleImage = digitalYellow;
          break;
      }
      break;
  }

  return (
    <div className={styles.Item}>
      <span>{t('examples.title')}</span>
      <img
        className={styles.Image}
        src={exampleImage}
        alt={props.case + ': ' + props.color}
      />
      {
        // @TODO: need to rebuild component depend on frok image component
        /* <figure className="a-image">
        <div className="a-image__ratioWrapper">
          <img
            src="https://frok.ui.bosch.tech/cdn/example-image-1600w.jpg"
            srcSet="
        https://frok.ui.bosch.tech/cdn/example-image-400w.jpg   400w,
        https://frok.ui.bosch.tech/cdn/example-image-800w.jpg   800w,
        https://frok.ui.bosch.tech/cdn/example-image-1600w.jpg 1600w
      "
            alt="Lorem ipsum dolor sit amet"
          />
        </div>
      </figure> */
      }
    </div>
  );
}
