import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  caseLocationdesign,
  caseClothing,
  caseDigital,
  caseGiveaways,
  casePowerpoint,
  casePrint,
} from '../../assets/img';
import { isHexCode } from '../../helper/functions/isHexCode';
import { removeSpacesFromString } from '../../helper/functions/removeSpacesFromString';
import './SelectableTile.scss';

type CaseImageMapping = {
  [key: string]: string;
};

const caseImageMapping: CaseImageMapping = {
  Standortgestaltung: caseLocationdesign,
  LocationDesign: caseLocationdesign,
  Digital: caseDigital,
  Druck: casePrint,
  Print: casePrint,
  Bekleidung: caseClothing,
  Clothing: caseClothing,
  PowerPoint: casePowerpoint,
  Werbegeschenke: caseGiveaways,
  GiveAways: caseGiveaways,
};

export interface SelectableTileProps {
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  change?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  name: string;
  gradation?: string;
  value: string;
  background: string | any;
  isSelected?: boolean;
  step?: string;
}

export function SelectableTile(props: SelectableTileProps) {
  let caseImage;

  const { t } = useTranslation(['common', 'cases']);
  const cases = t('cases:cases', { returnObjects: true });

  for (const [, value] of Object.entries(cases)) {
    if (props.label === value.label) {
      caseImage = caseImageMapping[removeSpacesFromString(value.label)];
    }
  }

  const trimName = props.name.replace(/ /g, '');
  const trimLabel = props.label.replace(/ /g, '');
  const concatedLabel = trimName + '_' + trimLabel;
  const borderClass =
    trimLabel === 'White' || 'Weiß' ? 'a-background__white' : '';
  const radioCaseClass = props.name === 'case' ? 'RadioCase' : '';

  return (
    <div
      className={`${radioCaseClass} a-selectable-tile a-selectable-tile--radio -large`}
    >
      <input
        aria-label={props.label}
        checked={props.isSelected}
        id={concatedLabel}
        name={props.name}
        onChange={props.change}
        type={`radio`}
        value={props.value}
      />
      <label aria-label={props.label} htmlFor={concatedLabel}>
        <div className={`radio-container`}></div>
        {props.name === 'case' && (
          <figure className={`a-image`}>
            <div className={`a-image__ratioWrapper`}>
              <img alt={props.label} className={`LabelImage`} src={caseImage} />
            </div>
          </figure>
        )}
        {isHexCode(props.background) && props.name === 'color' && (
          <div className={`a-background ${borderClass}`}>
            <div
              className={`a-background__ratioWrapper`}
              style={{ backgroundColor: props.background }}
            ></div>
          </div>
        )}
        <div className={`a-text`}>
          <p className={`LabelText -size-m highlight a-text__headline`}>
            {props.label}
          </p>
          {props.gradation && (
            <p className={`LabelGradation -size-s a-text__subheadline`}>
              {props.gradation}
            </p>
          )}
        </div>
      </label>
    </div>
  );
}
