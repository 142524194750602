// case
import caseLocationdesign from './cases/locationdesign.png';
import caseClothing from './cases/clothing.png';
import caseDigital from './cases/digital.png';
import caseGiveaways from './cases/giveaways.png';
import casePowerpoint from './cases/powerpoint.png';
import casePrint from './cases/print.png';
// print
import printBlue from './print/print_blue.png';
import printGreen from './print/print_green.png';
import printPurple from './print/print_purple.png';
import printTurquoise from './print/print_turquoise.png';
import printWhite from './print/print_white.png';
// powerpoint
import powerpointBlue from './powerpoint/powerpoint_blue.png';
import powerpointGreen from './powerpoint/powerpoint_green.png';
import powerpointPurple from './powerpoint/powerpoint_purple.png';
import powerpointTurquoise from './powerpoint/powerpoint_turquoise.png';
// clothing
import clothingBlack from './clothing/Clothing-Black.png';
import clothingBlue from './clothing/Clothing-Blue.png';
import clothingConvoy from './clothing/Clothing-Convoy.png';
import clothingGraphite from './clothing/Clothing-Graphite.png';
import clothingGray from './clothing/Clothing-Gray.png';
import clothingGreen from './clothing/Clothing-Green.png';
import clothingPurple from './clothing/Clothing-Purple.png';
import clothingTurquoise from './clothing/Clothing-Turquoise.png';
import clothingWhite from './clothing/Clothing-White.png';
// giveaways
import giveawaysBlack from './giveaways/Give-Aways-Black.png';
import giveawaysBlue from './giveaways/Give-Aways-Blue.png';
import giveawaysGray from './giveaways/Give-Aways-Gray.png';
import giveawaysGreen from './giveaways/Give-Aways-Green.png';
import giveawaysPurple from './giveaways/Give-Aways-Purple.png';
import giveawaysTurquoise from './giveaways/Give-Aways-Turquoise.png';
import giveawaysWhite from './giveaways/Give-Aways-White.png';
// locationdesign
import locationdesignBlue from './locationdesign/Location-Design-Blue.png';
import locationdesignBrownYellow from './locationdesign/Location-Design-Brown-Yellow.png';
import locationdesignCoolGray from './locationdesign/Location-Design-Cool-Gray.png';
import locationdesignGray from './locationdesign/Location-Design-Gray.png';
import locationdesignGreen from './locationdesign/Location-Design-Green.png';
import locationdesignGreenYellow from './locationdesign/Location-Design-Green-Yellow.png';
import locationdesignPurple from './locationdesign/Location-Design-Purple.png';
import locationdesignRed from './locationdesign/Location-Design-Red.png';
import locationdesignTurquoise from './locationdesign/Location-Design-Turquoise.png';
import locationdesignWarmGray from './locationdesign/Location-Design-Warm-Gray.png';
// digital
import digitalBlue from './digital/digital_blue.png';
import digitalGreen from './digital/digital_green.png';
import digitalPurple from './digital/digital_purple.png';
import digitalRed from './digital/digital_red.png';
import digitalTurquoise from './digital/digital_turquoise.png';
import digitalYellow from './digital/digital_yellow.png';

export {
  caseLocationdesign,
  caseClothing,
  caseDigital,
  caseGiveaways,
  casePowerpoint,
  casePrint,
  printBlue,
  printGreen,
  printPurple,
  printTurquoise,
  printWhite,
  powerpointBlue,
  powerpointGreen,
  powerpointPurple,
  powerpointTurquoise,
  clothingBlack,
  clothingBlue,
  clothingConvoy,
  clothingGraphite,
  clothingGray,
  clothingGreen,
  clothingPurple,
  clothingTurquoise,
  clothingWhite,
  giveawaysBlack,
  giveawaysBlue,
  giveawaysGray,
  giveawaysGreen,
  giveawaysPurple,
  giveawaysTurquoise,
  giveawaysWhite,
  locationdesignBlue,
  locationdesignBrownYellow,
  locationdesignCoolGray,
  locationdesignGray,
  locationdesignGreen,
  locationdesignGreenYellow,
  locationdesignPurple,
  locationdesignRed,
  locationdesignTurquoise,
  locationdesignWarmGray,
  digitalBlue,
  digitalGreen,
  digitalPurple,
  digitalRed,
  digitalTurquoise,
  digitalYellow,
};
