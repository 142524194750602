import { t } from 'i18next';
import { replacePlaceholder } from '../../helper/functions/replacePlaceholder';
import { Case } from '../../helper/interface/cases.interface';
import { Button } from '../Button';
import { ColorGradations } from '../ColorGradations';
import styles from './Accordion.module.scss';

export interface AccordionProps {
  accordionIsOpen?: any;
  case: string;
  color: string | [];
  gradation?: string;
  id: number;
  sub?: string;
  title: string;
}

export function Accordion(props: AccordionProps) {
  const caseData: Case = t(`cases:cases.${props.case}`, {
    returnObjects: true,
  });

  console.log(caseData);

  // replace placeholder substring with current color name
  const accordionTitle = replacePlaceholder(
    props.title,
    typeof props.color === 'string' ? props.color : ''
  );

  return (
    <div
      className={`a-accordion ${
        props.accordionIsOpen ? 'a-accordion--open' : ''
      }  ${styles.Item}`}
      id={`ao_${props.id}`}
    >
      <div className="a-accordion__headline">
        <h2 className="a-accordion__headline-heading highlight h3">
          {accordionTitle}
        </h2>
        <Button
          class={`a-accordion__headline-button -primary`}
          icon={true}
          iconName={`down`}
          iconTitle={`arrow down`}
          iconTypeClass={`accordion__headline-icon`}
          iconType={`boschicon-bosch`}
          id={`additionalOptions_${props.id}`}
          labelText={``}
          label={false}
        />
      </div>
      <div
        className={`a-accordion__content ${styles.Content}`}
        id={`content additionalOptions_${props.id}`}
        role="region"
        aria-labelledby={`content additional options accordion ${props.id}`}
      >
        {props.sub && (
          <div className={styles.SubHeading}>
            <h5>{props.sub}</h5>
          </div>
        )}
        {props.case === 'Bekleidung' || props.case === 'Clothing' ? (
          <div className={styles.clothingCopy}>
            <p>
              {caseData.additional[0].copy}{' '}
              <a
                href={caseData.additional[0].link}
                target="_blank"
                rel="noreferrer"
              >
                {caseData.additional[0].linkTitle}
              </a>
              .
            </p>
          </div>
        ) : (
          <ColorGradations
            case={props.case}
            color={props.color}
            gradation={props.gradation}
          />
        )}
      </div>
    </div>
  );
}
