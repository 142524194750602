export function getLastWordAndPartBefore(
  str: string | undefined
): [string, string] {
  if (!str || str.trim().length === 0 || str === undefined) {
    return ["", ""];
  }

  const words = str.split(" ");
  if (words.length <= 1) {
    return [str, ""];
  }

  const lastWord = words.pop();
  if (!lastWord || lastWord.trim().length === 0) {
    return ["", str];
  }

  const partBefore = words.join(" ");
  return [lastWord, partBefore];
}
