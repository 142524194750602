export function naturalMatch(str: string) {
  const arr = [];
  const num_re = /^(\D+)?(\d+)?(.*)$/;
  let s = str;
  while (s) {
    const match = s.match(num_re);
    if (!match) {
      break;
    }
    if (match[1]) {
      arr.push(match[1].trim());
    }
    if (match[2]) {
      arr.push(Number(match[2].trim()));
    }
    s = match[3];
  }
  return arr;
}
