export function goToAnchor(anchorName: string) {
  const anchor = document.getElementById(anchorName);
  if (anchor) {
    anchor.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
