import styles from './Step.module.scss';

export interface StepProps {
  step: string;
  title: string;
  intro?: string;
}

export function Step(props: StepProps) {
  return (
    <div className={styles.Content} id={`step${props.step}`}>
      <h2>
        <div
          className={`a-badge ${styles.Badge}`}
          tabIndex={0}
          role="status"
          aria-live="off"
        >
          {props.step}
        </div>
        {props.title}
      </h2>
      {props.intro && props.intro.length > 0 && <p>{props.intro}</p>}
    </div>
  );
}
