import { useTranslation } from 'react-i18next';
import { Link } from './../../components';
import styles from './Download.module.scss';

export interface DownloadProps {
  case: string;
  color: string;
}

const getFilesByColor = (
  fileData: any[],
  colorName: string,
  caseName: string,
  fileType: string
) => {
  const downloadFiles = fileData.filter(
    (item) =>
      item.case === caseName &&
      item.name === colorName &&
      item.type === fileType
  );
  return downloadFiles;
};

export function Download(props: DownloadProps) {
  const { t } = useTranslation(['common', 'downloads']);
  const translatedDownloadFilesData: [] = t('downloads:files', {
    returnObjects: true,
  });
  let links: JSX.Element[] = [];
  const singleFiles = getFilesByColor(
    translatedDownloadFilesData,
    props.color,
    props.case,
    'link'
  );
  const additionalFiles = getFilesByColor(
    translatedDownloadFilesData,
    t('downloads.additional'),
    props.case,
    'link'
  );
  const bundledFiles = getFilesByColor(
    translatedDownloadFilesData,
    props.color,
    props.case,
    'button'
  );
  const mergedFiles = [...singleFiles, ...additionalFiles, ...bundledFiles];

  if (Array.isArray(mergedFiles) && mergedFiles.length > 0) {
    mergedFiles.forEach((item, index) => {
      links.push(
        <Link
          ariaLabel={item.aria}
          download={item.download}
          href={`${process.env.PUBLIC_URL}/download/${item.slug}/${item.href}`}
          icon={true}
          iconDirection={'left'}
          iconName={item.icon}
          iconTitle={item.title}
          iconType={'boschicon-bosch'}
          key={index}
          labelText={
            item.type === 'button' ? t('downloads.button') : item.label
          }
          target={item.target}
          variant={item.type}
        />
      );
    });
  }

  return (
    <div className={styles.List}>
      <h3>{t('downloads.title')}</h3>
      {links && links.length ? links : t('downloads.error')}
    </div>
  );
}
