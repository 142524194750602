import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { replacePlaceholder } from '../../helper/functions/replacePlaceholder';
import { Notification } from '../Notification';

import styles from './Button.module.scss';

export interface ButtonProps {
  action?: string;
  ariaLabel?: string;
  class?: string;
  copy?: boolean;
  disabled?: boolean;
  icon?: boolean;
  iconDirection?: string;
  iconName?: string;
  iconTitle?: string;
  iconType?: string;
  iconTypeClass?: string;
  id?: string;
  label?: boolean;
  labelText: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  tooltip?: boolean;
  tooltipText?: string;
  tooltipType?: string;
  tooltipWidth?: string;
  variant?: string;
}

Button.defaultProps = {
  copy: false,
  disabled: false,
  icon: false,
  label: true,
  tooltip: false,
};

export function Button(props: ButtonProps) {
  const { t } = useTranslation(['common']);
  const buttonId = props.id ? { id: props.id } : {};
  const buttonVariant = props.variant ? `a-button--${props.variant}` : '';
  const buttonRef = useRef<HTMLButtonElement>(null);
  const customClass = props.class ? props.class : '';
  const iconClass = !props.icon ? '-without-icon' : '';
  const labelClass = !props.label ? '-without-label' : '';
  const ariaLabel = props.ariaLabel
    ? replacePlaceholder(props.ariaLabel, props.labelText)
    : props.labelText;
  const ariaLabelAttr = ariaLabel ? { 'aria-label': ariaLabel } : {};
  const disabledAttr = props.disabled ? { disabled: props.disabled } : {};
  const tooltipAttr =
    props.tooltip && props.tooltipText
      ? { 'data-tooltip': props.tooltipText }
      : {};
  const tooltipTypeAttr =
    props.tooltip && props.tooltipType
      ? { 'data-tooltip-type': props.tooltipType }
      : {};
  const tooltipWidthAttr =
    props.tooltip && props.tooltipWidth
      ? { 'data-tooltip-width': props.tooltipWidth }
      : {};
  const dataFrokActionAttr = props.action
    ? { 'data-frok-action': props.action }
    : {};
  const copyAttr = props.copy ? { 'data-copy': props.copy } : {};
  const iconTitle = props.iconTitle ? props.iconTitle : props.iconName;
  const iconTypeClass = props.iconTypeClass
    ? props.iconTypeClass
    : 'button__icon';
  const iconIdentifierClass =
    props.iconType && props.iconName
      ? `${props.iconType}-ic-${props.iconName}`
      : '';
  const [showCopyMessage, setShowCopyMessage] = useState<boolean>(false);
  const [uniqueId, setUniqueId] = useState<string>('');
  const dataFrokNotificationAttr = props.action
    ? { 'data-frok-notification': `toast-message-${uniqueId}` }
    : {};

  useEffect(() => {
    const uid = Date.now().toString(36);
    setUniqueId(uid);
  }, []);

  useEffect(() => {
    if (showCopyMessage) {
      const timeoutId = setTimeout(() => {
        setShowCopyMessage(false);
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [showCopyMessage]);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (event.currentTarget.dataset.copy) {
      event.preventDefault();
      navigator.clipboard.writeText(buttonRef.current?.innerText || '');
      setShowCopyMessage(true);
    }
  };

  const onClickAttr = props.onClick ? { onClick: handleClick } : {};

  return (
    <div {...dataFrokNotificationAttr}>
      <button
        ref={buttonRef}
        type={`button`}
        className={`a-button ${buttonVariant} ${iconClass} ${labelClass} ${customClass} ${styles.Element}`}
        {...buttonId}
        {...ariaLabelAttr}
        {...copyAttr}
        {...dataFrokActionAttr}
        {...disabledAttr}
        {...onClickAttr}
        {...tooltipAttr}
        {...tooltipTypeAttr}
        {...tooltipWidthAttr}
      >
        {props.icon && iconClass === '' && (
          <i
            className={`a-icon a-${iconTypeClass} ${iconIdentifierClass}`}
            title={iconTitle}
          ></i>
        )}
        {props.label && (
          <span className={`a-button__label`}>{props.labelText}</span>
        )}
      </button>
      {showCopyMessage && (
        <Notification
          banner={true}
          bannerVisibility={showCopyMessage}
          icon={``}
          iconType={'boschicon-bosch'}
          id={`toast-message-${uniqueId}-label`}
          message={t('common:app.clipboardMessage')}
          type={`neutral`}
        />
      )}
    </div>
  );
}
