import '@bosch/frontend.kit-npm/dist/frontend-kit.css';
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  useNavigate,
  useLocation,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import i18n from './services/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const LanguageSwitcher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const detectedLanguage = location.pathname;
  const segment = detectedLanguage.split('/')[1];

  useEffect(() => {
    i18n.changeLanguage(segment);
    if (segment === 'de') {
      navigate('/de');
    } else {
      navigate('/en');
    }

    // set html lang attribute to current locale
    i18n.on('languageChanged', (lng) => {
      document.documentElement.setAttribute('lang', lng);
    });
  }, [navigate, segment]);

  return null;
};

root.render(
  <React.StrictMode>
    <Suspense fallback={<div className={'loader'}>Loading...</div>}>
      <BrowserRouter>
        <LanguageSwitcher />
        <Routes>
          <Route path='/en' element={<App />} />
          <Route path='/de' element={<App />} />
          <Route path='/' element={<Navigate to='/en' />} />
          {/* <Route path='/' element={<App />} /> */}
        </Routes>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
