import { useTranslation } from 'react-i18next';
import { Link } from '../Link';
import styles from './RelatedLinks.module.scss';
import { Case } from '../../helper/interface/cases.interface';

export interface RelatedLinksProps {
  prop?: string;
  case: string;
}

const returnRelatedLinksEntries = (
  relatedData: any[],
  relatedIds: number[] | undefined
) => {
  const relatedLinksEntries = relatedData.filter((item) =>
    relatedIds?.includes(item.id)
  );
  return relatedLinksEntries;
};

export function RelatedLinks(props: RelatedLinksProps) {
  const { t } = useTranslation(['common', 'cases', 'related']);
  const caseData: Case = t(`cases:cases.${props.case}`, {
    returnObjects: true,
  });
  const relatedData: [] = t('related:links', { returnObjects: true });
  const selectedCase = caseData;
  const relatedIdsBySelectedCase = selectedCase.related;
  const relatedLinks = returnRelatedLinksEntries(
    relatedData,
    relatedIdsBySelectedCase
  );

  let links: JSX.Element[] = [];

  if (Array.isArray(relatedLinks) && relatedLinks.length > 0) {
    relatedLinks.forEach((item, index) => {
      links.push(
        <li className={styles.Item} key={index}>
          <Link
            ariaLabel={`${t('related.copyAria')}`}
            download={item.download}
            href={item.href}
            icon={true}
            iconDirection={`right`}
            iconName={item.icon}
            iconTitle={item.title}
            iconType={`ui`}
            labelText={item.label}
            target={item.target}
            variant={item.type}
          />
        </li>
      );
    });
  }

  return (
    <div className={styles.LinkList}>
      <h2>{t('related.title')}</h2>
      {relatedLinks && relatedLinks.length ? (
        <ul className={styles.List}>{links}</ul>
      ) : (
        t('related.error')
      )}
    </div>
  );
}
