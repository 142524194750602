import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectableTile, Step } from './../../components';
import styles from './CaseList.module.scss';

export interface CaseListProps {
  onCaseSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CaseList(props: CaseListProps) {
  const [isSelected, setIsSelected] = useState<string>('');
  const { t } = useTranslation(['common', 'cases']);

  const cases = t('cases:cases', { returnObjects: true });

  const radioChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSelected(event.target.value);
    if (props.onCaseSelect) {
      props.onCaseSelect(event);
    }

    // jump to next step
    const stepID = t('cases.stepId') + 1;
    setTimeout(() => {
      const stepElement = document.getElementById(`step` + stepID);
      if (stepElement) {
        stepElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  };

  const caseList: JSX.Element[] = [];

  for (const [key, value] of Object.entries(cases)) {
    caseList.push(
      <div key={key} className={styles.Item}>
        <SelectableTile
          background={value.image}
          change={radioChangeHandler}
          gradation={''}
          isSelected={isSelected === value.label}
          label={value.label}
          name={'case'}
          value={value.label}
        />
      </div>
    );
  }

  return (
    <div className={styles.TileList}>
      <Step step={t('cases.stepId')} title={t('cases.stepTitle')}></Step>
      <div className={styles.Items}>{caseList}</div>
    </div>
  );
}
