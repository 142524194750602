import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '../locales/{{lng}}/{{ns}}.json',
    },
    lng: 'en',
    supportedLngs: ['en', 'de'],
    fallbackLng: 'en',
    debug: false,
    ns: ['common', 'cases', 'colors', 'notifications', 'hints', 'downloads'],
    defaultNS: 'common',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
};

export const getLanguageFromPath = (path: string): string => {
  const languages = ['en', 'de'];
  const segments = path.split('/');

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (languages.includes(segment)) {
      return segment;
    }
  }

  return i18n.language;
};
