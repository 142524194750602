import { useRef } from 'react';
import { getFilenameFromUrl } from '../../helper/functions/getFilenameFromUrl';
import { getLastWordAndPartBefore } from '../../helper/functions/getLastWordAndPartBefore';
import { goToAnchor } from '../../helper/functions/goToAnchor';
import { replacePlaceholder } from '../../helper/functions/replacePlaceholder';
import styles from './Link.module.scss';

export interface LinkProps {
  anchor?: boolean;
  ariaLabel?: string;
  class?: string;
  disabled?: boolean;
  download?: boolean;
  href: string;
  icon?: boolean;
  iconDirection?: string;
  iconName?: string;
  iconTitle?: string;
  iconType?: string;
  label?: boolean;
  labelText: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  target?: string;
  to?: string;
  tooltip?: string;
  tooltipType?: string;
  tooltipWidth?: string;
  variant?: string;
}

Link.defaultProps = {
  disabled: false,
  download: false,
  icon: false,
  iconDirection: 'left',
  iconType: 'ui',
  label: true,
  target: '_self',
  tooltip: false,
};

export function Link(props: LinkProps) {
  const linkVariant =
    props.variant && props.variant !== 'link' ? `a-link--${props.variant}` : '';
  const disabledClass = props.disabled ? '-disabled' : '';
  const iconClass = props.icon ? '-icon' : '';
  const customClass =
    props.class && props.class.length !== 0 ? props.class : '';
  const linkText = props.labelText
    ? props.labelText
    : getFilenameFromUrl(props.href);
  const iconTitle = props.iconTitle ? props.iconTitle : props.iconName;
  const iconIdentifierClass =
    props.iconType && props.iconName
      ? `${props.iconType}-ic-${props.iconName}`
      : '';
  const linkRef = useRef<HTMLAnchorElement>(null);

  const ariaLabel = props.ariaLabel
    ? replacePlaceholder(props.ariaLabel, props.labelText)
    : props.labelText;
  const ariaLabelAttr = ariaLabel ? { 'aria-label': `${ariaLabel}` } : {};
  const anchorAttr = props.anchor ? { 'data-anchor': props.anchor } : {};
  const downloadAttr = props.download ? { download: props.download } : {};
  const tooltipAttr = props.tooltip ? { 'data-tooltip': props.tooltip } : {};
  const tooltipWidthAttr =
    props.tooltip && props.tooltipWidth
      ? { 'data-tooltip-width': props.tooltipWidth }
      : {};
  const tooltipTypeAttr =
    props.tooltip && props.tooltipType
      ? { 'data-tooltip-type': props.tooltipType }
      : {};
  const linkTextLastWord = getLastWordAndPartBefore(linkText)[0];
  const linkTextBefore = getLastWordAndPartBefore(linkText)[1];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.parentElement?.dataset.anchor) {
      if (props.to) {
        event.preventDefault();
        let act = document.getElementById(props.to);
        if (act) {
          let aButton: any = act.querySelector('.a-accordion__headline-button');

          if (
            aButton &&
            !aButton.parentNode.parentNode?.classList.contains(
              'a-accordion--open'
            )
          ) {
            aButton.click();
          }
          goToAnchor(props.to);
        }
      }
    }
  };

  const onClickAttr = props.onClick ? { onClick: handleClick } : {};

  const icon = () => {
    return (
      <i className={`a-icon ${iconIdentifierClass}`} title={iconTitle}></i>
    );
  };

  const label = () => {
    return <span>{linkText}</span>;
  };

  const iconRight = () => {
    return (
      <>
        <span>{linkTextBefore} </span>
        <span>
          {linkTextLastWord}
          {icon()}
        </span>
      </>
    );
  };

  const iconLeft = () => {
    return (
      <>
        {icon()}
        {label()}
      </>
    );
  };

  const labelWithIcon = () => {
    if (
      !props.iconDirection ||
      props.iconDirection === null ||
      props.iconDirection === ''
    )
      return;

    if (props.iconDirection === 'right') {
      return iconRight();
    }

    return iconLeft();
  };

  return (
    <div
      className={`a-link ${linkVariant} ${disabledClass} ${iconClass} ${customClass} ${styles.Element}`}
      {...anchorAttr}
      {...tooltipAttr}
      {...tooltipWidthAttr}
      {...tooltipTypeAttr}
    >
      <a
        {...ariaLabelAttr}
        ref={linkRef}
        href={props.href}
        {...onClickAttr}
        target={props.target}
        {...downloadAttr}
      >
        {props.icon && iconClass !== '' ? labelWithIcon() : label()}
      </a>
    </div>
  );
}
