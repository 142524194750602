import React, { useEffect, useState } from 'react';
import styles from './LanguageChooser.module.scss';
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';

export function LanguageChooser() {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const { t } = useTranslation(['common']);
  const languageLabel = t('common:app.languageLabel');

  // set the initial value of the dropdown to the current language in the url
  useEffect(() => {
    const url = window.location.href;
    const lang = url.match(/\/(en|de)?$/)?.[1] || 'de';
    setSelectedValue(lang);
  }, []);

  // onChange handler for the dropdown
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // get the selected value
    const selectedValue = e.target.value;
    // change the url to the selected language
    const url = window.location.href;
    const newLang = selectedValue;
    const newUrl = url.replace(/\/(en|de)?$/, `/${newLang}`);
    window.location.href = newUrl;
  };

  return (
    <div className={styles.LangChooser}>
      <div className={`a-dropdown ${styles.dropdown}`}>
        <label htmlFor="3">{languageLabel}</label>
        <select
          id="3"
          aria-label="Please select your language"
          onChange={handleChange}
          value={selectedValue}
        >
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </select>
      </div>
    </div>
  );
}
