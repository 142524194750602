import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUsableIdFromString } from '../../helper/functions/createUsableIdFromString';
import { Case } from '../../helper/interface/cases.interface';
import {
  Download,
  Examples,
  Link,
  Notification,
  Step,
} from './../../components';
import styles from './Results.module.scss';

export interface ResultsProps {
  case: string;
  color: string;
  gradation: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const returnTranslatedSelectedColor = (
  colorData: any[],
  colorName: string,
  colorGradation: string
) => {
  const selectedColor = colorData.filter(
    (item) => item.name === colorName && item.gradation === colorGradation
  );
  return selectedColor;
};

const returnTranslatedNotificationValues = (
  notificationsData: any[],
  notificationsIds: number[] | undefined
) => {
  const selectedNotification = notificationsData.filter(
    (item: { id: number }) => notificationsIds?.includes(item.id)
  );
  return selectedNotification;
};

const FRONTENDKIT_URL = `${process.env.PUBLIC_URL}js/frontend-kit.js`;

export function Results(props: ResultsProps) {
  const [componentIsLoaded, setComponentIsLoaded] = useState<boolean>(false);
  // create colorlists for copy to clipboard buttons
  const colorCodeList: JSX.Element[] = [];
  const colorCodeListExtend: JSX.Element[] = [];
  const { t } = useTranslation([
    'common',
    'cases',
    'colors',
    'notifications',
    'hints',
  ]);
  // default case object by casename translated
  // Data
  // const translatedHintData: [] = t('hints:hints', { returnObjects: true });
  const translatedColorData: [] = t('colors:colors', { returnObjects: true });
  const translatedCaseData: Case = t(`cases:cases.${props.case}`, {
    returnObjects: true,
  });
  const translatedNotificationData: [] = t('notifications:notifications', {
    returnObjects: true,
  });
  // Cases
  const selectedTranslatedCase = translatedCaseData;
  // Notifications
  const notificationIdsBySelectedCase = selectedTranslatedCase.notifications;

  const notificationsBySelectedCase = returnTranslatedNotificationValues(
    translatedNotificationData,
    notificationIdsBySelectedCase
  );
  const notificationList: JSX.Element[] = [];
  notificationsBySelectedCase.forEach((item, index) => {
    notificationList.push(
      <Notification
        icon={item.icon}
        iconType={'boschicon-bosch'}
        id={`case_${createUsableIdFromString(
          props.case
        )}_result_notification_${index}`}
        key={index}
        message={item.message}
        type={item.type}
      />
    );
  });
  // get more shades text
  const getMoreLinkText = selectedTranslatedCase.more;
  // create color filter by selected case and codes prop
  const colorFilter = selectedTranslatedCase.codes;
  // get copy link button icon
  // const codesIcon = selectedTranslatedCase.codesIcon;
  // case powerpoint/office needed color extractor
  const gradationExtractor = 20;

  // Color Buttons
  let newColorGradation = '';
  let colorCodesButtonList = returnTranslatedSelectedColor(
    translatedColorData,
    props.color,
    props.gradation
  );
  let colorCodesButtonListExtend: any[] = [];
  // Color hints
  // let getColorHint = (key: string) => {
  //   return getValueByKey(getHintByColor(key, translatedHintData), 'hint');
  // };

  // handle click event
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  // check if component is loaded
  useEffect(() => {
    setComponentIsLoaded(true);
  }, []);

  // if component is loaded inject local fe-kit script
  useEffect(() => {
    if (componentIsLoaded) {
      const frontEndKit = document.createElement('script');
      frontEndKit.src = FRONTENDKIT_URL;
      frontEndKit.type = 'text/javascript';
      frontEndKit.async = false;
      document.body.appendChild(frontEndKit);
      return () => {
        document.body.removeChild(frontEndKit);
      };
    }
  }, [componentIsLoaded]);

  // in case of "PowerPoint", there is another HEX color with a gradation of 20
  if (props.case === 'PowerPoint') {
    newColorGradation = (
      Number(props.gradation) - Number(gradationExtractor)
    ).toString();
    colorCodesButtonListExtend = [
      ...colorCodesButtonListExtend,
      returnTranslatedSelectedColor(
        translatedColorData,
        props.color,
        newColorGradation
      )[0],
    ];

    if (colorCodesButtonListExtend) {
      colorCodesButtonListExtend.forEach((color) => {
        Object.entries(color).forEach(([key, value], index) => {
          if (colorFilter.indexOf(key) > -1) {
            colorCodeListExtend.push(
              <li key={index} className={styles.ColorBoxItem}>
                <p>{`${key}: ${value}`}</p>

                {/* <Button
                  action={`show`}
                  ariaLabel={`${t('colors.copyAria')}`}
                  class={styles.ColorCopyButton}
                  copy={true}
                  icon={true}
                  iconName={codesIcon}
                  iconType={`boschicon-bosch`}
                  key={index}
                  labelText={`${key} ${value}`}
                  onClick={handleClick}
                  tooltip={true}
                  tooltipText={getColorHint(key)}
                  tooltipWidth={`fixed`}
                  variant={`secondary`}
                /> */}
              </li>
            );
          }
        });
      });
    }
  }

  // filter colors by key and push list items to array
  if (colorCodesButtonList) {
    colorCodesButtonList.forEach((color) => {
      Object.entries(color).forEach(([key, value], index) => {
        // get the hints by color key
        if (colorFilter.indexOf(key) > -1) {
          colorCodeList.push(
            <li key={index + 1} className={styles.ColorBoxItem}>
              <p>{`${key}: ${value}`}</p>
              {/* <Button
                action={`show`}
                ariaLabel={`${t('colors.copyAria')}`}
                class={styles.ColorCopyButton}
                copy={true}
                icon={true}
                iconName={codesIcon}
                iconType={`boschicon-bosch`}
                key={index}
                labelText={`${key} ${value}`}
                onClick={handleClick}
                tooltip={true}
                tooltipText={getColorHint(key)}
                tooltipWidth={`fixed`}
                variant={`secondary`}
              /> */}
            </li>
          );
        }
      });
    });
  }

  return (
    <div className={`${styles.Grid} container full-bleed`}>
      <Step
        step={t('results.stepId')}
        title={t('results.stepTitle')}
        intro={t('results.stepIntro') as string}
      ></Step>
      <div className={`${styles.Wrapper} container full-bleed`}>
        <div className={`${styles.Box}`}>
          <div className={styles.ExampleBox + ' ' + styles.examples}>
            <Examples
              case={props.case}
              color={props.color}
              gradiation={props.gradation}
            />
          </div>
          <div className={styles.CodeBox + ' ' + styles.code}>
            <h3 className="h2">{t('results.title')}</h3>
            {colorCodeList && colorCodeList.length ? (
              <>
                <p>{props.color + ' ' + props.gradation}</p>
                <ul className={styles.ColorBoxList}>
                  {colorCodeList ? colorCodeList : t('results.error')}
                </ul>
              </>
            ) : (
              ''
            )}
            {colorCodeListExtend && colorCodeListExtend.length ? (
              <>
                <p>{props.color + ' ' + newColorGradation}</p>
                <ul className={styles.ColorBoxList}>
                  {colorCodeListExtend
                    ? colorCodeListExtend
                    : t('results.error')}
                </ul>
              </>
            ) : (
              ''
            )}
            {props.case === 'Print' &&
            (props.color === 'White' || props.color === 'Weiß') ? (
              ''
            ) : (
              <Link
                anchor={true}
                ariaLabel={getMoreLinkText}
                class={styles.MoreLink}
                href={'#'}
                icon={false}
                labelText={getMoreLinkText}
                onClick={handleClick}
                to={'ao_0'}
              />
            )}
          </div>
          <div className={styles.NotificationBox + ' ' + styles.notification}>
            {notificationList && notificationList.length
              ? notificationList
                ? notificationList
                : t('notifications.error')
              : ''}
          </div>
          <div className={styles.DownloadBox + ' ' + styles.download}>
            <Download case={props.case} color={props.color} />
          </div>
        </div>
      </div>
    </div>
  );
}
